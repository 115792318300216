.ShareMapTemplate {
  .Banner {
    .banner-wrap {
      text-align: center;
      padding-top: 9rem;

      .banner-img {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        top: 15%;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .ShareMapTemplate {
    .Banner {
      .banner-wrap {
        padding-top: 6rem;
      }
    }
  }
}
