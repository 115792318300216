.LicenseCodeUsedTitle {
  display: inline-block;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  cursor: pointer;

  h2 {
    font-size: 30px;
    font-weight: 500;
    color: #000;

    svg {
      color: #f3ab19;
    }
  }
}

@media all and (max-width: 768px) {
  .LicenseCodeUsedTitle {
    h2 {
      font-size: 22px;
    }
  }
}
@media all and (max-width: 500px) {
  .LicenseCodeUsedTitle {
    h2 {
      font-size: 18px;
    }
  }
}
