.select-search-box {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  width: 150px;
  z-index: 1;

  select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 10px 15px;
    outline: 0;
    border: none;
    border-radius: 5px;
    background: #ffffff;
    color: #333333;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 18px;
    height: 60px;
  }

  select::-ms-expand {
    display: none;
  }

  .select + .select {
    margin-top: 0px;
    margin-bottom: 35px;
  }

  .select select::-ms-expand {
    display: none;
  }

  .select select:hover,
  .select select:focus {
    color: #333333;
    background: #ffffff;
  }

  .select select:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .select__arrow {
    position: absolute;
    top: 24px;
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 14px 10px 0 10px;
    border-color: #2fae8e transparent transparent transparent;
  }

  .select select:hover ~ .select__arrow,
  .select select:focus ~ .select__arrow {
    border-top-color: #2fae8e;
  }

  .select select:disabled ~ .select__arrow {
    border-top-color: #ffffff;
  }
}

@media all and (max-width: 1024px) {
  .select-search-box {
    select {
      font-size: 16px;
    }
  }
}

@media all and (max-width: 768px) {
  .select-search-box {
    width: 100px;

    select {
      font-size: 14px;
    }
  }
}

@media all and (max-width: 500px) {
  .select-search-box {
    width: 100%;
    margin-bottom: 2%;
  }
}
