.NoShareMap {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;

    label {
        font-size: 20px;
        color: #999;

        span {
            color: #f3ab19;
        }
    }

    p {
        font-size: 20px;
        color: #999;
    }
}

@media all and (max-width:768px) {
    .NoShareMap {
        label {
            font-size: 18px;
        }

        p {
            font-size: 18px;
        }
    }
}

@media all and (max-width:500px) {
    .NoShareMap {
        label {
            font-size: 16px;
        }

        p {
            font-size: 16px;
        }
    }
}