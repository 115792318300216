.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  text-align: center;

  .popup-inner {
    position: relative;
    display: inline-block;
    margin-top: 5%;
  }
}
