.MainBanner {
  position: relative;
  height: 57rem;
  background: #00b9ef;
  overflow: hidden;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  .school-banner-content {
    // width: 1200px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    z-index: 0;

    .banner-text {
      text-align: center;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .banner-text-title {
        font-size: 4.5rem;
        color: #ffffff;
        font-weight: bold;
        font-family: 'NanumSquare', sans-serif;

        img {
          max-width: 100%;
        }
      }

      .banner-text-explain {
        margin-top: 15px;
        font-size: 1.5rem;
        color: #333333;
        font-weight: 500;
        margin-bottom: 5.932203389830508%;
      }
    }

    .banner-watch-now-button {
      text-align: center;
      width: 100%;
      margin-top: 2.932203389830508%;
      position: absolute;

      button {
        color: #ffffff;
        width: 29.16666666666667%;
        height: 70px;
        border: none;
        border-radius: 10px;
        background: #000;
        cursor: pointer;
        letter-spacing: 2px;
        font-size: 37px;
        font-weight: 800;

        &:hover {
          background: #00b9ef;
        }
      }
    }

    .banner-img {
      position: relative;
      height: 100%;
      z-index: -1;

      img {
        width: 100%;
        max-width: 100%;
      }

      .cloud1 {
        position: absolute;
        left: 4%;
        top: 24%;
        width: 11.01694915254237%;
      }

      .cloud2 {
        position: absolute;
        left: -14%;
        bottom: 44%;
        width: 18.64406779661017%;
      }

      .cloud3 {
        position: absolute;
        left: 20%;
        bottom: 30%;
        width: 14.40677966101695%;
      }

      .cloud4 {
        position: absolute;
        right: -4%;
        top: 25%;
        width: 16.94915254237288%;
      }

      .cloud5 {
        position: absolute;
        right: 15%;
        top: 46%;
        width: 14.40677966101695%;
      }

      .cloud6 {
        position: absolute;
        right: -6%;
        bottom: 32%;
        width: 11.86440677966102%;
      }

      .ari-vr {
        position: absolute;
        bottom: -1%;
        right: 39%;
        width: 20.76271186440678%;
      }

      .castle {
        position: absolute;
        right: 0%;
        bottom: -1%;
        width: 30.59322033898305%;
      }

      .dinosaur {
        position: absolute;
        left: 0%;
        bottom: -1%;
        width: 30.59322033898305%;
      }
    }
  }
}

@media all and (min-width: 1200px) {
  .school-banner-content {
    width: 1200px;
    padding: 0 10px;
  }
}

@media all and (max-width: 1024px) {
  .MainBanner {
    height: 38rem;

    .school-banner-content {
      .banner-text {
        padding-top: 0;
        .banner-text-title {
          font-size: 3.8rem;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .banner-img {
    display: none !important;
  }

  .MainBanner {
    height: 35rem;

    .school-banner-content {
      .banner-text {
        .banner-text-title {
          font-size: 3.2rem;
          padding: 0 2rem;
        }

        .banner-text-explain {
          font-size: 1.3rem;
        }

        .banner-watch-now-button {
          button {
            width: 80%;
            font-size: 1.3rem;
            height: 50px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .MainBanner {
    height: 400px;

    .school-banner-content {
      .banner-text {
        .banner-text-title {
          font-size: 30px;
        }

        .banner-text-explain {
          font-size: 0.8rem;
        }

        .banner-watch-now-button {
          button {
            width: 80%;
            font-size: 1rem;
            height: 50px;
          }
        }
      }
    }
  }
}
