.SchoolDownloadSpec {
  margin-bottom: 150px;
  margin-top: 5%;

  .download-headline {
    margin-top: 80px;
    padding-bottom: 10px;

    p {
      font-size: 30px;
      font-weight: 800;
      color: #333333;
    }
  }

  .download-spec-table-wrap {
    .download-spec-table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;

      tr {
        text-align: center;
        color: #333333;
        font-size: 20px;

        .download-col-first {
          padding-left: 2.23728813559322%;
          padding-right: 1rem;
          text-align: left;
          font-weight: 500;
          height: 80px;
        }

        .download-col-rest {
          width: 360px;
          height: 80px;
        }

        // td:first-child {
        //     padding-left: 50px;
        //     text-align: left;
        //     font-weight: 500;
        // }

        // td {
        //     width: 360px;
        //     height: 80px;
        // }
      }

      .download-spec-head {
        background: #caedfe;
        border-bottom: 3px solid #00b9ef;
        color: #00b9ef;
        font-size: 24px;
        font-weight: bold;
      }

      .download-spec-info {
        background: #ffffff;
        color: #333333;
        font-size: 20px;
        font-weight: 500;
        border-bottom: 1px solid #00b9ef;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .SchoolDownloadSpec {
    .download-spec-table-wrap {
      .download-spec-table {
        .download-spec-head {
          font-size: 20px;
        }

        .download-spec-info {
          font-size: 1rem;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .SchoolDownloadSpec {
    .download-headline {
      p {
        font-size: 24px;
        text-align: center;
      }
    }

    .download-spec-table-wrap {
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;

      .download-spec-table {
        width: auto;
        tr {
          .download-col-rest {
            width: 270px;
            text-align: left;
            padding-left: 1rem;
          }
        }

        .download-spec-head {
          font-size: 1rem;
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .SchoolDownloadSpec {
    .download-headline {
      p {
        font-size: 20px;
      }
    }
    .download-spec-table-wrap {
      .download-spec-table {
        .download-spec-head {
          font-size: 0.9rem;
        }

        .download-spec-info {
          font-size: 0.9rem;
        }
      }

      .download-spec-table {
        tr {
          .download-col-rest {
            width: 160px;
          }
        }
      }
    }
  }
}
