.IntroTop {
  .intro-top-header {
    font-size: 64px;
    text-align: center;
    margin-top: 6rem;
  }

  .intro-top-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
    border-radius: 32px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    margin-top: 4rem;

    .intro-top-left {
      flex: 1;
      text-align: center;
      padding: 0 2rem;

      p {
        font-size: 38px;
        font-weight: 800;
        margin-bottom: 1.5rem;
      }

      div {
        font-size: 30px;
        font-weight: 800;
        background-color: #00b9ef;
        border-radius: 16px;
        padding: 1.5rem;
        color: #fff;
        cursor: pointer;
      }
    }

    .intro-top-right {
      flex: 0.7;

      img {
        max-width: 80%;
      }
    }
  }

  .intro-desc {
    text-align: center;
    margin-top: 4rem;

    h3 {
      font-size: 41px;
      font-weight: 800;
      margin-bottom: 3rem;
    }
    div {
      font-size: 28px;
      font-weight: 400;
      word-break: keep-all;
      line-height: 1.25;
    }
  }
}

@media screen and (max-width: 1024px) {
  .IntroTop {
    .intro-top-box {
      flex-direction: column-reverse;
    }
  }
}

@media screen and (max-width: 768px) {
  .IntroTop {
    .intro-top-header {
      font-size: 40px;
    }

    .intro-top-box {
      .intro-top-left {
        p {
          font-size: 30px;
          margin-top: 1rem;
        }
      }
    }

    .intro-desc {
      padding: 0 2rem;
      margin-bottom: 2rem;

      h3 {
        font-size: 30px;
      }

      div {
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .IntroTop {
    .intro-top-header {
      font-size: 30px;
    }

    .intro-top-box {
      .intro-top-left {
        p {
          font-size: 25px;
          margin-top: 2rem;
        }

        div {
          font-size: 28px;
        }
      }
    }

    .intro-desc {
      padding: 0 1rem;

      h3 {
        font-size: 25px;
      }

      div {
        font-size: 18px;
      }
    }
  }
}
