.LicenseCodeUsedTerm {
  display: flex;
  margin: 1rem 0;
  .license-code-used-term-wrap {
    display: inline-block;
    padding: 0.8rem 0.7rem;
    border: 2px solid #f8f8f8;
    border-radius: 6px;
    display: flex;
    color: #707070;
    .license-code-term-left {
      margin-right: 0.8rem;
      font-weight: 500;
    }

    .license-code-term-right {
      display: flex;

      .license-code-term {
        padding: 0 1rem;
        margin-right: 0.2rem;
        border-radius: 6px;
        background-color: #f8f8f8;
        cursor: pointer;
      }
      .term-active {
        background-color: #f3ab19;
        color: #fff;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .LicenseCodeUsedTerm {
    .license-code-used-term-wrap {
      font-size: 0.9rem;
      width: 100%;
    }
  }
}
