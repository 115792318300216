.NotFoundInfo {
    text-align: center;

    .not-found-illust {
        padding-top: 100px;
    }

    .not-found-headline {
        margin-top: 55px;
        font-size: 24px;
        font-weight: 500;
        color: #2fae8e;
    }

    .not-found-subline {
        margin-top: 50px;
        font-size: 16px;
        color: #555555;
    }

    .not-found-thx {
        margin-top: 30px;
        margin-bottom: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #555555;
    }

    .not-found-btns {
        margin-top: 2rem;

        .not-found-button {
            width: 120px;
            height: 40px;
            color: #2fae8e;
            border: 1px solid #2fae8e;
            background: #ffffff;
            font-size: 16px;
            margin-right: 15px;
            margin-left: 15px;
            cursor: pointer;
            transition: all 0.2s linear;

            &:hover {
                background: #2fae8e;
                color: #ffffff;
            }
        }
    }
}

@media all and (max-width:768px) {
    .NotFoundInfo {
        .not-found-headline {
            margin-top: 20px;
            font-size: 18px;
        }

        .not-found-subline {
            margin-top: 25px;
            font-size: 14px;
        }

        .not-found-illust {
            img {
                width: 50%;
            }
        }
    }
}

@media all and (max-width:500px) {
    .NotFoundInfo {
        .not-found-headline {
            font-size: 14px;
        }

        .not-found-subline {
            font-size: 12px;
        }

        .not-found-thx {
            font-size: 14px;
        }


        .not-found-button {
            width: 35%;
        }
    }
}