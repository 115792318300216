.headroom {
  // background: #2fae8e;
  z-index: 9999 !important;
}

.headroom--pinned {
  // background: #2fae8e;
  box-shadow: 0px -16px 56px -4px #333;
}

.Header {
  height: 120px;
  width: 100%;

  .header-wrap {
    height: 100%;
    padding: 10px;
    margin: 0 auto;
    line-height: 96px;
  }

  .logo {
    float: left;

    img {
      vertical-align: middle;
      cursor: pointer;
      max-width: 100%;
    }
  }

  .nav {
    li {
      float: left;
      margin-left: 45px;
      font-size: 1rem;

      label {
        cursor: pointer;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

@media all and (min-width: 1200px) {
  .header-wrap {
    width: 1180px;
  }
}

@media all and (max-width: 1200px) {
  .Header {
    height: 75px;

    .header-wrap {
      padding: 0 2.666666666666667em;
      line-height: 75px;
    }

    .logo {
      img {
        width: 80%;
      }
    }

    .nav {
      display: none;
    }

    .HeaderRight {
      display: none;
    }
  }
}
