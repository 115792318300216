.input-search-box {
  position: relative;
  display: inline-block;
  z-index: 1;

  input[type='text'] {
    width: 450px;
    height: 60px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: #333333;
    display: inline-block;
    padding-left: 20px;
  }

  button {
    width: 75px;
    height: 60px;
    background: #00b9ef;
    border: none;
    cursor: pointer;
    border-radius: 0px 5px 5px 0px;
    position: absolute;
    right: 0;

    &:hover {
      background: #00b9ef;
    }
  }
}

@media all and (max-width: 1024px) {
  .input-search-box {
    input[type='text'] {
      font-size: 16px;
    }
  }
}

@media all and (max-width: 768px) {
  .input-search-box {
    input[type='text'] {
      font-size: 14px;
      width: 320px;
    }
  }
}

@media all and (max-width: 500px) {
  .input-search-box {
    width: 100%;

    input[type='text'] {
      font-size: 14px;
      width: 100%;
    }

    button {
      width: 50px;
    }
  }
}
