.ManualBox {
  padding: 2rem 0;
  padding-bottom: 10rem;

  h2 {
    padding: 1rem 0;
    font-weight: 800;
  }

  h3 {
    text-align: center;
    font-size: 41px;
    font-weight: 800;
    margin: 3rem 0;
  }
  .hrs {
    border-bottom: 2px solid #00b9ef;
  }
  .download-btn {
    width: 100%;
    text-align: center;

    .download-manual-button {
      position: relative;
      width: 100%;
      margin-top: 1rem;

      button {
        display: flex;
        width: 100%;
        border: none;
        background: #00b9ef;
        border-radius: 10px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        padding: 1.5rem;
        cursor: pointer;
        align-content: center;
        justify-content: center;
        align-items: center;

        &:hover {
          background: #00b9ef;
        }

        img {
          vertical-align: bottom;
          float: right;
          position: absolute;
          right: 2rem;
        }
      }
    }

    .download-manual-button-label {
      margin-top: 2rem;
      font-size: 16px;
      color: #555555;
    }
  }
}

@media screen and (max-width: 500px) {
  .ManualBox {
    .download-btn {
      .download-manual-button {
        button {
          justify-content: flex-start;
        }
      }
    }
  }
}
