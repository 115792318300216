.SchoolIntroVideo {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  text-align: center;

  .SchoolIntroVideo-inner {
    position: relative;
    display: inline-block;
    padding: 0 20px;
    margin-top: 100px;
  }
}

@media all and (min-width: 1200px) {
  .SchoolIntroVideo {
    .SchoolIntroVideo-inner {
      width: 1180px;
      height: 80%;
    }
  }
}

@media all and (max-width: 1199px) {
  .SchoolIntroVideo {
    .SchoolIntroVideo-inner {
      width: 80%;
      height: 60%;
    }
  }
}

@media all and (max-width: 1199px) {
  .SchoolIntroVideo {
    .SchoolIntroVideo-inner {
      width: 80%;
      height: 60%;
    }
  }
}

@media all and (max-width: 768px) {
  .SchoolIntroVideo {
    .SchoolIntroVideo-inner {
      width: 90%;
      height: 50%;
    }
  }
}

@media all and (max-width: 500px) {
  .SchoolIntroVideo {
    .SchoolIntroVideo-inner {
      height: 40%;
    }
  }
}
