.ShareMapDetail {
  .share-map-detail-title {
    line-height: 90px;
    background: #fff;
    text-align: left;
    padding-left: 50px;
    font-size: 30px;
    color: #333333;
    border-radius: 29.5px 29.5px 0 0;
    display: inherit;
  }

  .detail-close-box {
    float: right;
    padding-right: 50px;

    .close {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 50px;
      overflow: hidden;
      vertical-align: middle;
      cursor: pointer;

      &:hover {
        &::before,
        &::after {
          background: #333333;
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: #000;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }

      &.big {
        transform: scale(3);
      }

      &.hairline {
        &::before,
        &::after {
          height: 1px;
        }
      }

      &.thick {
        &::before,
        &::after {
          height: 4px;
          margin-top: -2px;
        }
      }

      &.black {
        &::before,
        &::after {
          height: 8px;
          margin-top: -4px;
        }
      }

      &.heavy {
        &::before,
        &::after {
          height: 12px;
          margin-top: -6px;
        }
      }

      &.pointy {
        &::before,
        &::after {
          width: 200%;
          left: -50%;
        }
      }

      &.rounded {
        &::before,
        &::after {
          border-radius: 5px;
        }
      }
    }
  }

  .share-map-detail-thumb {
    height: 600px;
    width: 100%;
    // border-radius: 0 0 29.5px 29.5px;
    background-repeat: round;
    background-color: #fff;
  }

  .share-map-detail-explain {
    border-radius: 0 0 29.5px 29.5px;
    background: #fff;
    text-align: left;
    padding: 30px 50px 30px 50px;
    font-size: 16px;
    color: #333333;
  }
}

@media all and (min-width: 360px) {
  .share-map-detail-popup {
    width: 360px;
  }
}

@media all and (min-width: 500px) {
  .share-map-detail-popup {
    width: 500px;
  }
}

@media all and (min-width: 768px) {
  .share-map-detail-popup {
    width: 768px;
  }
}

@media all and (min-width: 1200px) {
  .share-map-detail-popup {
    width: 1180px;
  }
}

@media all and (max-width: 1024px) {
  .share-map-detail-popup {
    .share-map-detail-thumb {
      height: 450px;
    }
  }
}

@media all and (max-width: 768px) {
  .popup-inner {
    margin-top: 20% !important;
  }

  .share-map-detail-popup {
    .share-map-detail-title {
      line-height: 70px;
      font-size: 22px;
    }

    .share-map-detail-thumb {
      height: 300px;
    }

    .detail-close-box {
      .close {
        width: 30px;
        height: 30px;
      }
    }

    .share-map-detail-explain {
      padding: 20px 50px 20px 50px;
      font-size: 15px;
    }
  }

  @media all and (max-width: 500px) {
    .share-map-detail-popup {
      .share-map-detail-thumb {
        height: 300px;
      }

      .share-map-detail-title {
        font-size: 18px;
        padding-left: 20px;
      }

      .share-map-detail-thumb {
        height: 230px;
      }

      .detail-close-box {
        padding-right: 20px;

        .close {
          width: 24px;
          height: 24px;
        }
      }

      .share-map-detail-explain {
        padding: 20px;
        font-size: 13px;
      }
    }
  }
}
