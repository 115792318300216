.Banner {
  height: 350px;
  background: #2fae8e;
  overflow: hidden;

  .banner-wrap {
    padding: 0 10px;
    margin: 0 auto;
    padding-top: 180px;

    .banner-text {
      font-size: 43px;
      font-weight: 800;
      color: #ffffff;
    }

    .banner-img {
      position: absolute;
      right: 11%;
      top: 16%;
      z-index: 1;
      display: table;

      img {
        max-width: 100%;
      }
    }

    .banner-search {
      margin-top: 3.389830508474576%;
    }
  }
}

@media all and (min-width: 1200px) {
  .Banner {
    .banner-wrap {
      width: 1180px;
    }
  }
}

@media all and (max-width: 1199px) {
  .Banner {
    .banner-wrap {
      .banner-img {
        right: 0;
        top: 10%;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .Banner {
    .banner-wrap {
      .banner-text {
        font-size: 30px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .Banner {
    height: 300px;
    text-align: center;

    .banner-wrap {
      .banner-text {
        font-size: 24px;
      }
    }
  }
}
