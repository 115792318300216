.ShareMapList {
  margin-top: 100px;
  margin-bottom: 75px;
  text-align: center;

  .pagination {
    display: block;
    margin-top: 6.355932203389831%;

    li {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      width: 30px;
      line-height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      -ms-user-select: none;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      outline: none;

      a {
        outline: none;
        display: block;
      }

      &:hover {
        transition: all 0.2s linear;
        background: #00b9ef;
        color: #ffffff;
      }
    }

    .rc-pagination-jump-prev:after,
    .rc-pagination-jump-next:after {
      content: '\2022\2022\2022';
      display: block;
      letter-spacing: 2px;
      color: #555555;
      font-size: 16px;
      margin-top: 0px;
    }

    .rc-pagination-jump-prev:after,
    .rc-pagination-jump-next:after {
      content: '\2022\2022\2022';
      display: block;
      letter-spacing: 2px;
      color: #555555;
      font-size: 16px;
      margin-top: 0px;
    }

    .rc-pagination-jump-next:hover:after {
      color: #ffffff;
      font-size: 16px;
      content: '\BB';
    }

    .rc-pagination-jump-prev:hover:after {
      color: #ffffff;
      font-size: 16px;
      content: '\AB';
    }

    .rc-pagination-item-active {
      transition: all 0.2s linear;
      background: #00b9ef;
      border-radius: 20px;
      color: #ffffff;
    }
  }
}

@media all and (max-width: 1024px) {
  .ShareMapList {
    .ShareMapItem {
      .share-map-thumb {
        width: 100%;
        height: 550px;
      }

      .share-map-info {
        .share-map-title {
          font-size: 16px;
          max-width: 20rem;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .ShareMapList {
    margin-top: 8.474576271186441%;

    .ShareMapItem {
      .share-map-thumb {
        width: 100%;
        height: 400px;
      }

      .share-map-info {
        .share-map-title {
          font-size: 14px;
          max-width: 13rem;
          max-height: 3.5rem;
        }
      }
    }

    .pagination {
      li {
        font-size: 14px;
        width: 26px;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .ShareMapList {
    .ShareMapItem {
      .share-map-thumb {
        width: 100%;
        height: 250px;
      }
    }
  }

  .no-share-map {
    label {
      font-size: 16px;
    }

    p {
      font-size: 16px;
    }
  }
}
