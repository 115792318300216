.IntroContents {
  display: flex;
  flex-wrap: wrap;

  .intro-content-box {
    width: calc(50% - 6rem);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: center;
    border-radius: 32px;
    margin: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content-thumb {
      flex: 1;
      width: 100%;
      img {
        width: 100%;
      }
    }

    .content-title {
      flex: 0.2;
      color: #00b9ef;
      font-size: 30px;
      font-weight: 800;
      padding: 1rem 0;
      word-break: keep-all;
    }

    .content-desc {
      color: #494645;
      font-size: 22px;
      font-weight: 500;
      word-break: keep-all;
      padding: 2rem 1rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .IntroContents {
    .intro-content-box {
      width: calc(100% - 6rem);
    }
  }
}

@media screen and (max-width: 500px) {
  .IntroContents {
    .intro-content-box {
      width: calc(100% - 2rem);
      margin: 1rem;
    }
  }
}
