.LicenseCodeUsedList {
  table {
    text-align: center;
    thead {
      tr {
        td {
          background: #2fae8e;
          padding: 0.8rem 1rem;
          color: #fff;
          border-radius: 6px;
        }
      }
    }
    tbody {
      tr {
        td:first-child {
          font-weight: 600;
          background-color: #f4f4f4;
        }
        td {
          background: #f8f8f8;
          padding: 0.8rem 1rem;
          color: #707070;
          border-radius: 6px;
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .LicenseCodeUsedList {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;

    table {
      width: auto;
      font-size: 0.9rem;
    }
  }
}
